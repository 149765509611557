@font-face {
  font-family: axioz;
  src: url(./Assets/Fonts/Azonix.otf);
}

@font-face {
  font-family: para;
  src: url(./Assets/Fonts/PlayfairDisplaySC-Regular.ttf);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#home {
  display: block;
}

.mobile-hero {
  display: none;
}

body {
  background: #0E0D10 !important;
  position: relative;
  height: auto;
  min-height: 100vh;
  color: #fff !important;
  cursor: url('./Assets/Images/cursor-svg.svg'), auto !important;
}

body::-webkit-scrollbar {
  display: none;
}

.custom-cursor {
  cursor: url('.../../Assets/Images/cursor-svg.svg'), auto !important;

}

body::after {
  width: 90%;
  border-left: 1px solid rgba(255, 255, 255, 0.22);
  border-right: 1px solid rgba(255, 255, 255, 0.22);
  height: 1250vh;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 5%;
  opacity: 0.5;
  z-index: -1;
}

body::before {
  width: 30%;
  border-left: 1px solid rgba(255, 255, 255, 0.22);
  border-right: 1px solid rgba(255, 255, 255, 0.22);
  height: 1250vh;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 35%;
  opacity: 0.5;
  z-index: -1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: axioz;
}

.App p {
  font-family: para;
  text-transform: capitalize !important;
}

.header-con {
  position: relative;

}

.header-con::after {
  content: '';
  position: fixed;
  top: -500px;
  left: -500px;
  border-radius: 646.64px;
  opacity: 0.5975;
  background: radial-gradient(70.71% 70.71% at 50% 50%, #F6BEFF 0%, rgba(0, 0, 0, 0.00) 66%);
  width: 866px;
  height: 866px;
  flex-shrink: 0;
  z-index: -1;
}

.nav-links {
  font-size: 20px !important;
  color: #fff !important;
  font-family: para !important;
  text-transform: capitalize !important;
  margin: 0px 20px !important;
  padding: 5px 15px !important;
  border: 2px solid transparent;
}

.nav-links:hover {
  border-bottom: 2px solid #CBA5E9;
  border-left: 2px solid #CBA5E9;
  border-right: 2px solid #CBA5E9;
  border-radius: 50%;
  font-size: 20px !important;
  color: #fff !important;
  font-family: para !important;
  text-transform: capitalize !important;
  margin: 0px 20px !important;
  padding: 5px 15px !important;
}

.nav-links.active {
  border-bottom: 2px solid #CBA5E9;
  border-left: 2px solid #CBA5E9;
  border-right: 2px solid #CBA5E9;
  border-radius: 50%;
  font-size: 20px !important;
  color: #fff !important;
  font-family: para !important;
  text-transform: capitalize !important;
  margin: 0px 20px !important;
  padding: 5px 15px !important;
}

.navs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);

}

.resume-btn {

  border-radius: 3.68px;
  background: rgba(246, 190, 255, 0.70);
  backdrop-filter: blur(5.25px);
  backdrop-filter: blur(5.25px);
  color: transparent;
  padding: 7.999px 45.852px 17.191px 50px;
  font-family: para;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.05px;
  /* 100.227% */
  text-transform: capitalize !important;
  position: relative;

}

.resume-btn::after {
  content: 'Resume';
  border-radius: 3.68px;
  border: 1px solid rgba(159, 161, 163, 0.15);
  background: rgba(245, 247, 250, 0.10);
  backdrop-filter: blur(5.25px);
  position: absolute;
  top: 5px;
  right: 5px;
  display: inline-flex;
  padding: 7.999px 45.852px 17.191px 50px;
  justify-content: flex-end;
  align-items: center;
  color: #0E0D10;
}

.resume-btn:hover::after {
  top: 0;
  left: 0;
  cursor: pointer;
}

.text-main {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 60px;
  width: 90%;
}

.inner-con {
  width: 10%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 60px;
}

.inner-con h3 {
  color: #FFF;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
}

.inner-con h3 img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.inner-con p {
  color: #FFF;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 168.624%;
  /* 42.156px */
  font-variant: small-caps;
}

.inner-con span {
  color: #F6BEFF;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  position: relative;
}

.inner-con span img {
  content: '';
  position: absolute;
  /* background-image: url(./Assets/Images/vinith-svg.png); */
  width: fit-content;
  background-size: cover;
  background-position: calc();
  background-repeat: no-repeat;
  height: 100%;
}

.main-img {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 60px;
}

.main-img img {
  width: 100%;
  height: 70vh;
  display: none;
  object-fit: contain;
  transition: 'width 1s'
}

.full-main {
  position: relative;
  height: 300vh;
}


.scroll-down-btn {
  position: fixed;
  right: -88%;
  top: 50%;
  width: 100%;
  height: 100vh;
}

.scroll-down-btn button {
  transform: rotate(90deg);
  font-family: axioz;
  font-size: 12px;
  padding: 3px 9px;
  background-color: #fff;
  opacity: 0.8;
}

.scroll-down-btn button img {
  transform: rotate(-90deg);
  margin: 0px 13px;
}

.inner-con p img {
  position: relative;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.inner-con p img::after {
  content: 'fertyuty5ety6h5';
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-image: url(./Assets/Images/tea-smoke-1.svg);
  background-size: cover;
  background-position: center;
}

/* ============about============ */
.about-con {
  position: relative;
  width: 100%;
}

.new-con {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section-titl-right {
  width: 90%;
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}



.sec-titl {
  position: relative;
  width: fit-content;
  object-fit: contain;
}

.sec-titl h2 {
  position: relative;
  font-size: 50px;

}

.sec-titl span {
  position: absolute;
  top: 0;
  left: 0;
}

.monkey {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(./Assets//Images/monkey-group.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.monkey img {
  position: relative;
}

/* .monkey span {
  position: absolute;
  top: 25%;
  left: 44%;
  transform: translate(-50%, -50%);
  width: 70px;
} */

.eyee {
  display: flex;
  align-items: center;
  width: 70px;
  justify-content: space-between;
  background-color: red !important;
}

.eyess {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.eyes {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #000;
  transform: translate3d(0, 0, 0);
  position: absolute;
  top: -140%;
  left: -13px;
  right: -13px;
  /* right: 900px; */
}

.monkey {
  border-right: 3px solid transparent;
  padding: 15px;
  border-image: url(./Assets/Images/border-line.png) 50 round;
}

.monkey::after {
  position: absolute;
  top: -10%;
  right: -10%;
  content: '';
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 646.64px;
  opacity: 0.4;
  background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(246, 190, 255, 0.50) 0%, rgba(0, 0, 0, 0.00) 66%);
}

.monkey::before {
  position: absolute;
  bottom: -10%;
  right: -10%;
  content: '';
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 646.64px;
  opacity: 0.4;
  background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(246, 190, 255, 0.50) 0%, rgba(0, 0, 0, 0.00) 66%);
}

.about-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 218.6%;
  font-variant: small-caps;
  width: 80%;
  margin: 0 auto;
}

/* ===============work con */

.work-con {
  position: relative;
  height: auto;
  margin: 100px auto;
}

.section-titl-left {
  width: 90%;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 40% auto;
}

.second-title h2 {
  color: #F6BEFF;
  text-align: left;
  font-family: axioz;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.work-desc {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 218.624%;
  font-variant: small-caps;
}

.torquee {
  margin: 30px 0px;
  position: relative;

}

.torquee-con {
  background-color: #3C3040;
  width: 99%;
  height: 100px;
  margin-bottom: 100px;
  transform: rotate(-3deg);
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.torquee-con span {
  font-family: axioz;
  font-size: 40px;
}

.bellow-line {
  font-family: axioz;
  font-size: 40px;
}

.text-stock {
  -webkit-text-stroke: 1px #d896e2;
  opacity: 1;
  color: #3C3040;
}

.text-stocks {
  -webkit-text-stroke: 1px #fff;
  opacity: 0.5;
  color: #3C3040;
}

.text-opac {
  color: #fff;
  opacity: 0.5;
}

.main-project-con {
  height: 80vh;
  position: sticky;
  top: 200px;
}

.project-titl {
  position: sticky;
  top: 100px;
}

.work-desc {
  background-color: #0E0D10;
  height: 80vh;
}

.second-title {
  background-color: #0E0D10;
  padding-top: 20px;
}

.work-img {
  width: 100%;
  height: fit-content;
  object-fit: cover;
}

.chain-con {
  position: absolute;
  top: 0;
  z-index: -1;
  left: 50%;
  transform: translate(-50%);
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

}

.chain-con .img-1 {
  height: 180px;
  width: 20px;
  object-fit: contain;
  margin-top: 50px;
  transition: 5s;
  transform: rotate(5deg);
  animation: first-chain 5s infinite;
}

@keyframes first-chain {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.chain-con .img-2 {
  height: 220px;
  object-fit: contain;
  width: 20px;
  transform: rotate(5deg);
  animation: first-chain 5s infinite;
}

.work-box-con {
  border: 2px solid #F6BEFF;
  background: rgba(246, 190, 255, 0.15);
  width: 100%;
  height: 40 0px;
  z-index: 100;
  animation: box-ani 5s infinite;
  transition: 5s;
  overflow: hidden;
  position: relative;
  /* transform: translateX(20px); */

}

.work-box-con::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: #3C3040;
  width: 100%;
  height: 35px;
  z-index: -1;
}

@keyframes box-ani {
  0% {
    transform:translateX(0px) rotateX(0deg);
  }

  25% {
    transform:translateX(10px) rotateX(0deg);
  }

  50% {
    transform:translateX(0px) rotateX(0deg);
  }

  75% {
    transform:translateX(-10px) rotateX(0deg);
  }

  100% {
    transform:translateX(0px) rotateX(0deg);
  }
}


.scrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin: 10px auto;
  z-index: 20;
  /* animation: ani-scrow 5s infinite; */

}

@keyframes ani-scrow {
  0% {
    transform: translate(0px);
  }

  25% {
    transform: translateX(20px);
  }

  50% {
    transform: translate(0px);
  }

  75% {
    transform: rotateX(20px);
  }

  100% {
    transform: translateX(0px);
  }
}

.list-of-skills {
  display: flex;
  justify-content: space-around;
  padding: 30px 0px;
}

.list-of-skills p {
  border-radius: 27.5px;
  border: 1px solid #F6BEFF;
  background: rgba(246, 190, 255, 0.30);
  padding: 10px 20px;
}

.list-of-skills :nth-child(1) {
  transform: rotate(30deg);
}

.list-of-skills :nth-child(2) {
  transform: rotate(-30deg);
}

.list-of-skills :nth-child(3) {
  transform: rotate(50deg);
}

.list-of-skills :nth-child(4) {
  transform: rotate(-30deg);
}

.list-of-skills1 {
  display: flex;
  justify-content: space-around;
}

.list-of-skills1 p {
  border-radius: 27.5px;
  border: 1px solid #F6BEFF;
  background: rgba(246, 190, 255, 0.30);
  padding: 10px 20px;
}

.list-of-skills1 :nth-child(1) {
  transform: rotate(-30deg);
}

.list-of-skills1 :nth-child(2) {
  transform: rotate(60deg);
}

.list-of-skills1 :nth-child(3) {
  transform: rotate(-50deg);
}

.center-tex {
  color: #F6BEFF;
}

.center-diamond {
  text-align: center;
  margin: 100px 0px;
}

.work-texs {
  font-size: 80px;
  font-family: axioz;
  color: #F6BEFF;
  position: relative;
  width: fit-content;
}

.work-texs::before {
  position: absolute;
  content: '';
  background-color: #d8cdcd;
}

.second-texts {
  width: fit-content;
  position: relative;
  font-size: 80px;
  font-family: axioz;
}

.second-texts::before {
  position: absolute;
  content: '';
  background-image: url(./Assets/Images/diamond.png);
  background-position: right;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.social-link {
  display: flex;
  margin: 100px auto;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  line-height: normal;
  cursor: pointer;
  z-index: 199;
}

.social-link a {
  cursor: pointer;
}

.social-link span {
  font-size: 22px;
  font-weight: 700;
  font-family: para;
  padding: 0px 20px;
}

.arrow-img {
  animation: arrow-right 3s infinite;
}

@keyframes arrow-right {
  0% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(5px);
  }
}

footer {
  border-top: 1px solid #fff;
  z-index: 10;
}

.footer-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 40px;
  font-size: 22px;
  font-weight: 400;
}

.names {
  color: #F6BEFF;
  font-weight: 700;
}

.hidden {
  transform: translateY(-100%);
}

.visible {
  transform: translateY(0);
}

.scroll-button img {
  animation: scrollbutton 3s infinite;
  transform: rotate(-90deg);

}

@keyframes scrollbutton {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.mail,
.linked,
.github {
  color: #fff;
  text-decoration: none;
}

.soc-link:hover {
  color: #ccc;
}

.soc-link:hover .arrow-right {
  transform: translateX(10px);
  transition: all 0.5s ease-in-out;
}

.hand {
  animation: hands 3s infinite;
}

@keyframes hands {
  0% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0px);
  }
}

.down-arrow-img {
  animation: downarrow 3s infinite;
  transform: rotateY('45deg');
}

@keyframes downarrow {
  0% {
    transform: translateX(5px);

  }

  50% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-5px);
  }
}


/* ================responsive============= */

@media screen and (min-width: 320px) and (max-width: 480px) {
  .logo {
    display: none;
  }

  .resume-btn,
  .resume-btn::after {
    padding: 3px 10px;
    font-size: 14px;
  }

  #home {
    display: none;
  }

  .mobile-hero {
    display: block;
    margin-top: 100px;
  }

  .mob-name-svg {
    width: 80px;
  }

  .main-mob-con h3 {
    font-size: 20px;
  }

  .name-mob {
    position: relative;
  }

  .name-mob img {
    position: absolute;
    object-fit: cover;
    top: 0px;
  }

  .main-p {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 168.624%;
  }

  .mini-monitor img {
    object-fit: cover;
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }

  .sec-titl h2 {
    position: relative;
    font-size: 30px;

  }

  #about {
    margin-top: 100px;
  }

  .mob-eye {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #000;
    top: 80%;
    left: 59%;
    transform: translate(-50%, -50%);
  }

  .eyess {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
  }

  .about-text {
    width: 95%;
    margin: 0 auto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 218.6%;
  }

  .main-project-con {
    height: 90vh;
    position: sticky;
    top: 10px;
  }

  .work-desc {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 218.624%;
  }

  .second-title h2 {
    font-size: 20px;
  }

  .list-of-skills {
    padding: 10px 0px;
  }

  .work-box-con {
    height: 300px;
  }

  .list-of-skills p {
    border-radius: 27.5px;
    border: 1px solid #F6BEFF;
    background: rgba(246, 190, 255, 0.30);
    padding: 5px 5px;
  }

  .looping-content span img {
    padding: 0px 20px;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .looping-content span {
    font-size: 20px;
  }

  .work-desc {
    background-color: #0E0D10;
    height: 50vh;
  }

  .think-white {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 208.76%;
  }

  .center-tex {
    font-size: 16px;
    font-weight: 400;
  }

  .work-texs {
    font-size: 32px;
  }

  .second-texts {
    font-size: 40px;
  }

  .social-link {
    flex-direction: column;
  }

  .linked,
  .mail,
  .github {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: space-around;
    white-space: none;
  }

  .linked img,
  .mail img,
  .github img {
    width: 30px;
  }

  .linked span,
  .mail span,
  .github span {
    font-size: 18px;
  }

  .bellow-line {
    font-family: axioz;
    font-size: 20px;
  }

  .bellow-line-imgs {
    width: 90px !important;
    height: 90px !important;
    object-fit: cover;
  }

  .footer-line {
    font-size: 16px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: 400;
  }

  .footer-line p {
    font-size: 12px;
  }




  body::after,
  body::before {
    height: 500vh;
  }

  .new-con {
    height: 50vh;
  }

}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .logo {
    display: none;
  }


  .resume-btn,
  .resume-btn::after {
    padding: 5px 10px;
    font-size: 16px;
  }

  #home {
    display: none;
  }

  .mobile-hero {
    display: block;
    margin-top: 100px;
  }

  .mob-name-svg {
    width: 80px;
  }

  .main-mob-con h3 {
    font-size: 20px;
  }

  .name-mob {
    position: relative;
  }

  .name-mob img {
    position: absolute;
    object-fit: cover;
    top: 0px;
  }


  .looping-content span img {
    padding: 0px 20px;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .looping-content span {
    font-size: 20px;
  }

  .main-p {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 168.624%;
  }

  .mini-monitor img {
    object-fit: cover;
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }

  .mob-eye {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: #000;
    top: 90%;
    left: 59%;
    transform: translate(-50%, -50%);
  }

  .eyess {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
  }


  .social-link {
    flex-direction: column;
  }

  .linked,
  .mail,
  .github {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: space-around;
    white-space: none;
  }

  .linked img,
  .mail img,
  .github img {
    width: 30px;
  }

  .linked span,
  .mail span,
  .github span {
    font-size: 18px;
  }

  .bellow-line {
    font-family: axioz;
    font-size: 20px;
  }

  .bellow-line-imgs {
    width: 90px !important;
    height: 90px !important;
    object-fit: cover;
  }

  .footer-line {
    font-size: 16px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: 400;
  }

  .footer-line p {
    font-size: 12px;
  }

  .coder {
    font-size: 12px;
  }

  body::after,
  body::before {
    height: 500vh;
  }

  .new-con {
    height: 50vh;
  }

}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .inner-con {
    width: 10%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 60px;

  }

  .inner-con h3 {
    color: #FFF;
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
  }

  .inner-con h3 img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  .inner-con p {
    color: #FFF;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 168.624%;
    /* 42.156px */
    font-variant: small-caps;
  }

  .inner-con span {
    color: #F6BEFF;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    position: relative;
  }

  .inner-con span img {
    content: '';
    position: absolute;
    /* background-image: url(./Assets/Images/vinith-svg.png); */
    width: fit-content;
    background-size: cover;
    background-position: calc();
    background-repeat: no-repeat;
    height: 100%;
  }
}





/* =====smoke wrapper */

.cup-wrap {
  width: 20px;
  height: 20px;
  position: relative;
}

.cup {
  position: absolute;
  bottom: 14px;
  left: 35px;
  width: 100%;
  height: 100%;
  background-image: url(./Assets/Images/cup-coffe.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}

.mobile-cup {
  left: 90% !important;
}

.smoke-wrap {
  position: absolute;
  left: 100%;
  transform: translateX(50%);
  bottom: 20px;
  z-index: 10;
}

.smoke,
.smoke2,
.smoke3 {
  /* filter: blur(10px) ; */
  transform-origin: 50% 50%;
}

.smoke {
  animation: smoke1 3s linear infinite;
  animation-delay: 0.5s;
}

.smoke2 {
  animation: smoke2 3s linear infinite;
  animation-delay: 1.5s;
}

.smoke3 {
  width: 500px;
  animation: smoke3 4s linear infinite;
  animation-delay: 2.5s;
}

@keyframes smoke1 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(-1, 1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-10px) scale(-1, 1.05);
    opacity: 0.5;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-20px) scale(-1, 1.1);
    opacity: 1;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-30px) scale(-1, 1.15);
    opacity: 0.5;
  }

  100% {
    filter: blur(3px);
    transform: translateY(-40px) scale(-1, 1.2);
    opacity: 0;
  }
}

@keyframes smoke2 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-10px) scale(1.05);
    opacity: 0.5;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-20px) scale(1.1);
    opacity: 1;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-30px) scale(1.15);
    opacity: 0.5;
  }

  100% {
    filter: blur(3px);
    transform: translateY(-40px) scale(1.2);
    opacity: 0;
  }
}

@keyframes smoke3 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-20px) scale(1.05);
    opacity: 0.5;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-40px) scale(1.1);
    opacity: 1;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-60px) scale(1.15);
    opacity: 0.5;
  }

  100% {
    filter: blur(3px);
    transform: translateY(-80px) scale(1.2);
    opacity: 0;
  }
}

.know-more-btn {
  border: none;
  outline: none;
  background-color: #d896e2;
  padding: 2px 15px;
  border-radius: 5px;
  text-decoration: none !important;
  color: #0E0D10 !important;

}

.links-tags {
  text-decoration: none !important;
  color: #0E0D10 !important;
  text-transform: capitalize !important;
  font-weight: 600;
}

.under-procee {
  position: absolute;
  bottom: 210vh;
  right: 0;
  font-family: axioz;
}

.under-symbol {
  font-size: 30px;
}


/* ============= */

.eyes {
  width: 60%;
  height: 100px;
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 20px;
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(./Assets/Images/monkey-group.png); */

}

.eye-container {
  position: relative;
  width: 30%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 40%;
  top: 20%;
  background-color: #CBA5E9;
}

.eye {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  /* background-color: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.pupil {
  width: 10px;
  height: 10px;
  border-radius: 101px;
  background-color: #000;
}

@media screen and (min-width:1025px) and (max-width:1200px){
.nav-links{
  font-size: 18px !important;
}
.eyes {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #000;
  transform: translate3d(0, 0, 0);
  position: absolute;
  top: 75px;
  left: 13px;
  right: -13px;
  /* right: 900px; */
}
.chain-con {
  width: 75%;
}
.main-project-con {
  height: 50vh;
  position: sticky;
  top: 100px;
}
body::after,body::before{
  height: 800vh;
}
}

@media screen and (min-width:1201px) {
  .scrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 79%;
    margin: 10px auto;
    z-index: 20;
    /* animation: ani-scrow 5s infinite; */
  
  }

  .new-con {
    height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .main-project-con{
    height: 60vh;
  }
  body::after,body::before{
    height: 700vh;
  }
  .work-box-con{
    height: 75vh;
  }
  .list-of-skills{
    padding: 50px 0;
  }
  .eyes{
    top:-20px;
  }
}

@media screen and (min-width:769px) and (max-width:1024px) {
  .nav-links{
    font-size: 18px !important;
  }
  .eyes{
    margin-top: 30%;
  }
  .scrow{
    width: 78%;
  }
}

@media screen and (max-width:768px) and (min-width:481px) {
  .sec-titl h2{
    font-size: 30px !important;
  }
  .monkey{
   display: none;
  }
  .main-project-con {
    height: 100vh;
    position: sticky;
    top: 10px;
}
.work-img{
  width: 300px;
  height: 300px;
  object-fit: cover;
}
.work-desc{
  font-size: 14px !important;
}
.work-box-con{
  width: 100%;
}
.list-of-skills{
  font-size: 12px;
}
.chain-con{
  width: 55%;
}
.center-diamond span , .center-diamond h3{
  font-size: 40px !important;
}
}

@media  screen and (max-width:480px) {
  .monkey{
    display: none;
  }
  .nav-mob-slide{
    height: 100vh !important;
    background-color: rgba(0, 0, 0, 1);
    position: relative;
    left: 0;
    gap: 100px;

  }
  .nav-links{
    font-size: 30px !important;
    text-align: center;
  }

  .resume-btn{
    position: absolute;
    top: 8px;
    right: 10px;
  }
  
  .main-project-con{
    top: 200px;
    /* height: 60vh; */
  }
  
}